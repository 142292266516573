@use 'variables';

.component-login-setPassword {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 400px;

    h2 {
        padding-bottom: 20px;
    }

    &__field {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 20px;
        label {
            margin-bottom: 13px;
            @include variables.bold-font;
        }

        input {
            height: 50px;
            padding-left: 15px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            width: 100%;
        }
    }

    &__button {
        display: flex;
        margin-top: 20px;
        p {
            cursor: pointer;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e2e2e2;
            font-size: 0.875rem;
            padding: 14px 20px;
            @include variables.bold-font;
            &:hover {
                background: #00A053;
                border: 1px solid #00A053;
                color: #ffffff;
            }
        }
    }

}
